
/* Reset
-------------------------------------------------- */
*, *:before, *:after {
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure,
footer, header, main, nav, section, summary {
  display: block;
}
  
/* Site
-------------------------------------------------- */

html, body {
  height: 100%;
  font-size: $font-size-normal;
  font-family: "futura-pt";
  font-weight: $font-weight-normal;
  background-color:$oe-light-gray;
}

section {
  margin-bottom: $section-size;
  
  @media screen and (min-width: $screen-xl) {
    margin-bottom: $section-size-l;
  }
}

/* Pix
-------------------------------------------------- */

img {
  display: block;
  max-width: 100%;
}

/* image fader */
.image-container {
  padding: 0;

  .img-fluid {
    width: 100%;
  }
}

img:not([src]) {
  visibility: hidden;
}

@-moz-document url-prefix() {
  img:-moz-loading {
    visibility: hidden;
  }
}

/* Links
-------------------------------------------------- */
a {
  color: $primary-button-bg;
  transition: color .3s, background .3s, border .3s;
}

a:hover {
  color: $primary-button-broder;
  text-decoration: none;
}

.activate {
  background-color: $oe-light-gray;
}
  
/* List
-------------------------------------------------- */

ul {
  list-style: url($yellow-arrow);
}
  
/* Titles
-------------------------------------------------- */
h1, h2, h3, h4, h5, h6 {
  font-weight: $font-weight-bold;
}

h1, h2, h3 {
  text-align: left;
}

h4, h5, h6 {
  text-align: center;
}

h1, h4 {
  font-size: $font-size-super-extra-large;
}

h2, h5 {
  font-size: $font-size-extra-large;
}

h3, h6 {
  font-size: $font-size-large;
}

h1, h2, h4, h5 {
  letter-spacing: 1px;
}
  
/* Base
-------------------------------------------------- */
hr {
  border-top: 5px solid $oe-yellow;
}

video {
  width: 100%;
}

strong, label, legend {
  font-weight: $font-weight-bold;
}

.anchor, .landing-page, .navbar, footer ul {
  font-variant: small-caps;
}

/* Button
-------------------------------------------------- */

.btn-primary {
  border-color: $primary-button-broder;
  background-color: $primary-button-bg;
}

.btn-primary:hover {
  border-color: $primary-button-bg;
  background-color: $primary-button-broder;
}

.btn {
  white-space: normal;
}

/* Layout
-------------------------------------------------- */
@media screen and (min-width: $layout-box-size) {
  .box-layout {
    width: $layout-box-size;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: $layout-article-size) {
  .article-layout {
    width: $layout-article-size;
    margin-right: auto;
    margin-left: auto;
  }
}