/* Main
-------------------------------------------------- */
article {
  header {
    margin-bottom: $box-padding;
    
    h2 {
      font-size: $font-size-super-extra-large;
    }
  }
  
  hr {
    margin-bottom: 40px
  }
  
  
  ol, ul {
    margin-left: 30px;
  }
}

.oe-article-content {
  margin: $section-size 0;
  overflow: hidden;
  
  hr {
    margin-left: -20px;
    width: 120%;
  }

  @media screen and (min-width: $screen-xl) {
    margin: $section-size-l 0;
  }
}
  
.oe-article {
  padding: 0 20px;
    
  img {
      margin: 20px auto;
    }
}