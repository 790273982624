/* box
-------------------------------------------------- */
.box-container {
  margin: $container-space 0;
  padding: 0px $container-space;
  
    @media screen and (min-width: $screen-l-bs) {
      width: percentage(1/3);
    }
}

.box {
  position: relative;
  padding: $box-padding;
  border: 1px solid $oe-light-gray;
  outline: 1px solid $oe-lighter-gray;
  text-align: left;
  font-size: $font-size-large;
  background-color: #FFF
}
  
.box {
  color: $oe-black;

  &:hover {
    color: $oe-black;
    background-color: $oe-yellow;

    .yellow-Clip {
      background-image: url($black-clip);
    }
  }

}

.yellow-Clip {
  position: absolute;
  z-index: 999;
  height: 35px;
  width: 40px;
  left: -10px;
  top: 38px;
  overflow: visible;
  background-image: url($yellow-clip);
  background-repeat: no-repeat;
}
