/* Home
-------------------------------------------------- */
.fadein {
  position:relative;
  padding: 0;
  
  .fadein-img {
      position:absolute;
      padding: 0;
      animation-name: fade;
      animation-iteration-count: infinite;
      animation-duration: 18s;
  }
}

@keyframes fade {
  0%   { opacity: 1; }
  11.11%   { opacity: 0; }
  33.33%  { opacity: 0; }
  50.44%  { opacity: 0; }
  99%  { opacity: 1; }
  100% { opacity: 1; }
}

#f1 {
  animation-delay: 2s;
}
#f2 {
    animation-delay: 6s;
}
#f3 {
    animation-delay: 12s;
}

#slogan,
#usp {
  width: 81%;
  text-align: center;
  font-weight: $font-weight-bold;

  @media screen and (min-width: $screen-m) {
    width: 505px;
  }

  @media screen and (min-width: $screen-l) {
    width: 700px;
  }

  @media screen and (min-width: $screen-xl) {
    width: 900px;
  }

  @media screen and (min-width: 1300px) {
    width: $layout-box-size;
  }
}

#slogan {
  clear: both;
  margin: $section-size auto;
  border-bottom: 5px solid $oe-yellow;
  font-size: $font-size-extra-large;
  text-align: center;

  blockquote {
    font-size: 25px;
    margin-top: -25px;
  }

  @media screen and (min-width: $screen-m-bs) {
    font-size: $font-size-super-extra-large;
  }
}

#usp {
  margin: 20px auto $section-size auto;

  @media screen and (min-width: $screen-m-bs) {
    font-size: $font-size-extra-large;
  }

  @media screen and (min-width: $screen-xl) {
    margin-bottom: $section-size-l * 1.5;
  }
}

.box {
  @media screen and (min-width: $screen-l-bs) {
    height: 450px
  }

  @media screen and (min-width: 1150px) {
    height: 400px
  }
}