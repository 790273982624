/* quicknews */
$qn-left: 34px;
$qn-shadow-height: 20px;
$qn-border-width: 2px;

@mixin qn-sizes($height, $width, $container-right, $poly-size, $before-top, $link-padding, $font-size, $line-height) {
    width: $width;
    height: $height + $qn-border-width + $qn-shadow-height;
    top: -$height/2;
    right: $container-right;

    a {
        width: $width;
        height: $height;
        padding: $link-padding;
        font-size:  $font-size;
        line-height: $line-height;
        
        &:before {
            top: $before-top;
            @include triangle ($poly-size);
        }
    }
}

@media screen and (min-width: $screen-s-bs) {
    .quicknews-container {
        position: relative;

    }
}

.quicknews {
  @include qn-sizes(60px, 97%, 0, 30px, 0, 1px, $font-size-small, 19px);
  overflow: hidden;
  padding-left: $qn-left;
  
    a {
        position: relative;
        display: block;
        padding: 1px;
        border-right: $qn-border-width solid $oe-black;
        box-shadow: 0 5px $qn-shadow-height 0 $oe-black;
        background: $oe-yellow;
        color: $primary-button-bg;

        &:before {
            position: absolute;
            left: -$qn-left;
            border-right: $qn-left solid $oe-yellow;
            content: "";
        }

        &:hover {
            color: $oe-black;
        }
    }

    @media screen and (min-width: $screen-s-bs) {
        @include qn-sizes(80px, 70%, 2px, 41px, -1px, 5px, $font-size-normal, 23px);

        position: absolute;

        a {
            float: right;
        }
    }

    @media screen and (min-width: $screen-s-bs) {
        @include qn-sizes(100px, 65%, 2px, 52px, -1px, 10px, $font-size-large, 29px);
    }

    @media screen and (min-width: 1200px) {
        @include qn-sizes(120px, 55%, 2px, 61px, -1px, 10px, $font-size-extra-large, 33px);
    }
}